
































import Vue from 'vue';

import Recommended from '@/components/Recommended.vue';

export default Vue.extend({
	components: { Recommended },
	data () {
		return {
		};
	},
	beforeMount () {
		this.$store.dispatch('fetch', {
			fragment: `issue/${this.id}/`,
			endpoint: 'content',
			storeKey: 'issue',
			store: true
		});
	},
	computed: {
		id (): any { return this.$route.params.id },
		lang () : any { return (this as any).$lang(); },
		issue () : any { return this.$store.getters.issue || {}; }
	}
});
